import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import axios from "axios";
import Cookie from "js-cookie";

interface BalanceContextProviderProps {
  children: ReactNode;
}

interface BalanceActions {
  balance: number;
  setBalance: React.Dispatch<React.SetStateAction<number>>;
  updateBalance: () => void;
}

export const BalanceContext = createContext<BalanceActions>({
  balance: 0,
  setBalance: () => {},
  updateBalance: () => {},
});

export const BalanceContextProvider: React.FC<BalanceContextProviderProps> = ({
  children,
}) => {
  const [balance, setBalance] = useState(0);

  const fetchData = async () => {
    const cookie = Cookie.get("cookie");
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_MIDDLEWARE}adServer/user`,
        data: {
          cookie: cookie,
        },
      });

      const data = await response.data;
      if (data) {
        const userBalance = Number(data.user.balances?.advert);
        setBalance(userBalance);
      }
    } catch (err) {
      // Handle any errors
      console.log(`Error: `, err);
    }
  };

  const updateBalance = async () => {
    // Call fetchData to update balance from API
    await fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <BalanceContext.Provider value={{ balance, setBalance, updateBalance }}>
      {children}
    </BalanceContext.Provider>
  );
};

export const useBalance = (): BalanceActions => useContext(BalanceContext);
