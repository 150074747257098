import styles from "./dashboard.module.scss";

interface CardProps {
  caption: string;
  icon: string;
}

const Card: React.FC<CardProps> = ({ icon, caption }) => {
  return (
    <div className={styles.card}>
      <img src={icon} alt="icon" />
      <p>{caption}</p>
    </div>
  );
};

export default Card;
