import React from "react";
import styles from "../payments.module.scss";
import Card from "app/components/analytic/analytic";

interface IpaymentTable {
  invoiceId: string;
  amount: string;
  status: string;
  date: string;
}

interface CreditHistorySectionProps {
  PaymentTableData: IpaymentTable[];
}

/**
 * Allows users to view their past credit transactions.
 * @returns the Credit History section, displaying a table of credit histories.
 */

const PaymentTableSection: React.FC<CreditHistorySectionProps> = ({
  PaymentTableData,
}) => {
  return (
    <div className={styles.creditHistoryContainer}>
      <div className={styles.heading_with_button}>
        <h3 className={styles.subHeading}>Payments History</h3>
        {/* <button>Purchase Credits</button> */}
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Invoice ID</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          {PaymentTableData.length > 0 && (
          <tbody>
            {PaymentTableData?.map((paymentTable, index) => (
              <tr key={index}>
                <td>{paymentTable.invoiceId}</td>
                <td>${paymentTable.amount}</td>
                <td>{paymentTable.status}</td>
                <td>{paymentTable.date}</td>
              </tr>
            ))}
          </tbody>
          )}
        </table>
        {PaymentTableData.length === 0 &&  (
      <div className={styles.noDataContainer} style={{ textAlign: "center", marginTop: "50px" }}>
      
          <h1 className={styles.content}>
            No data found.
          </h1>
      </div>
    )}
      </div>
    </div>
  );
};

export default PaymentTableSection;
