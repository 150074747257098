import React from "react";
import styles from "../dashboard.module.scss";
import Card from "app/components/dashboard/welcome_card";

interface DashboardData {
  title: string;
  value: string;
  icon: string;
  caption: string;
}

interface AnalyticsSectionProps {
  dashboardData: DashboardData[];
}

/**
 * Renders the WelcomeSection component.
 * @returns dashboard data such as impressions, clicks, and profile views.
 */
const WelcomeSection: React.FC<AnalyticsSectionProps> = ({ dashboardData }) => {
  return (
    <div className={styles.dashboardContainer}>
      <h3 className={styles.heading}>Ads Manager</h3>
      <div className={styles.row}>
        {dashboardData?.map((data, index) => (
          <div className={styles.col} key={index}>
            <Card
              title={data.title}
              value={data.value}
              icon={data.icon}
              caption={data.caption}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default WelcomeSection;
