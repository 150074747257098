import React, { createContext } from "react";
import useUserApi from "./useUserApi";
import { UserData } from "app/core/common";

interface UserContextValue<T> {
  userInfo: UserData;
  checkingUserInfo: boolean;
  followingListLoaded: boolean;
  followingList: T;
  updateFollowingList: (list: T) => void;
  updateUserInfo: () => void;
}

const ConnectUserContext = createContext<UserContextValue<any>>({
  userInfo: null,
  checkingUserInfo: true,
  followingListLoaded: false,
  followingList: [],
  updateFollowingList: () => {},
  updateUserInfo: () => {},
});

export function UserProvider({ children }) {
  let { userInfo, checkingUserInfo, followingListLoaded, followingList, updateFollowingList, updateUserInfo } =
    useUserApi();

  return (
    <ConnectUserContext.Provider
      value={{
        userInfo,
        checkingUserInfo,
        followingListLoaded,
        followingList,
        updateFollowingList,
        updateUserInfo,
      }}
    >
      {children}
    </ConnectUserContext.Provider>
  );
}

export function useUser() {
  return React.useContext(ConnectUserContext);
}
