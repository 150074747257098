import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "../campaign.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { DirectusImage } from "app/core/common";
import { Card } from "semantic-ui-react";
import icon from "../../../../../assets/icons/pencil.png";
import { useHook } from "app/hooks/common";

function SingleCampaignView({ id }) {
  const { cookie, address } = useHook()
  const [message, setMessage] = useState("");

  const [paymentPopup, setpaymentPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(true);

  const [isEditing, setIsEditing] = useState(false);

  const [formData, setFormData] = useState<any>({
    nomination_type: null,
    nomination_name: null,
    nomination_category: null,
    nomination_url: null,
    budget: null,
    spents: null,
    newBudget: null,
    clicks: null,
    status: null,
    statusId: null,
    newStatus: null,
    first_name: "",
    url: "",
    avatar: "",
    create_date: "",
    display_name: "",
  });


  const getCampaign = async () => {
    try {
      if(cookie){
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_MIDDLEWARE}adServer/get_campaign`,
          data: {
            cookie: cookie,
            id: id,
          },
        });

        const nomination = await response.data;

        const user = await UserfetchData();
         setFormData({
          ...formData,
          nomination_type: nomination.category.name,
          nomination_name: nomination.name || "",
          nomination_category: nomination.category.name,
          nomination_url: nomination.nomination_url,
          budget: nomination.limits.budget.total,
          spents: nomination.counters.total_balance,
          clicks: nomination.counters.clicks,
          status: nomination.runstatus.name,
          statusId: nomination.runstatus.id,
          first_name: user.first_name,
          avatar: user.avatar,
          display_name: user.display_name,
        });

        setLoading(true);
      }
    } catch (error) {
      console.error("Error fetching nominations:", error);
    }
  };

  async function UserfetchData() {
    try {
      if(cookie){
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_MIDDLEWARE}user/userInfo`,
          data: {
            address: address,
            cookie: cookie,
          },
        });

        const user = await response.data[0];
        return user;
      }
    } catch (err) {
      // Handle any errors
      setLoading(true);
    }
  }

  useEffect(() => {
    getCampaign();
  }, []);

  const UpdateCampaign = async (value) => {
    try {
      if(cookie){
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_MIDDLEWARE}adServer/update-campaign`,
          data: {
            cookie: cookie,
            id: id,
            budget: formData.newBudget === null ? formData.budget : formData.newBudget,
            status: value ? value : formData.statusId
          },
        });
        if (response.data) {
          return response.data.campaign;
        }
        return false;
      }
    } catch (error) {
      console.error("Error update  campsign faild:", error);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      newBudget: e.target.value,
    });
  };

  const handleStatusChange = async (value) => {
    setFormData({
      ...formData,
      statusId: 4444,
      status: value === 4010 ? 'Running' : 'Paused'
    });
    const nomination = await UpdateCampaign(value);
      if (!nomination) {
        console.error("Campaign Updated Failed");
      }
      else{
         setFormData({
          ...formData,
          nomination_type: nomination.category.name,
          nomination_name: nomination.name || "",
          nomination_category: nomination.category.name,
          nomination_url: nomination.nomination_url,
          budget: nomination.limits.budget.total,
          spents: nomination.counters.total_balance,
          clicks: nomination.counters.clicks,
          status: nomination.runstatus.name,
          statusId: nomination.runstatus.id,
        });
      }
   
  };

  const handleSaveClick = async () => {
    if (formData.newBudget !== null && formData.budget !== formData.newBudget) {
      const status = await UpdateCampaign(null);
      if (!status) {
        console.error("Campaign Updated Failed");
      }
    }
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setFormData({
      ...formData,
      newBudget: formData.budget,
    });
    setIsEditing(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {loading ? (
          <div className={styles.form_container}>
            <h1 className={styles.heading}>Vote Nomination 1</h1>
            <div className={styles.form}>
              <div className={styles.view_bar}>
                <div className={styles.update_top}>
                  <p>
                    Views: <span>{formData.clicks}</span>{" "}
                  </p>
                  <p>
                    Amount Spent: <span>{formData.spents}</span>{" "}
                  </p>
                </div>
              </div>

                <h1 className={styles.subHeading}>Ad details</h1>
              <div className={styles.single_form}>
                <div className={styles.preview_row}>
                  <div className={styles.form_col}>
                    <div className={styles.preview_input}>
                      <p>
                        Ad Type: <span>{formData.nomination_name}</span>
                      </p>
                      <p>
                        Genre: <span>{formData.nomination_category}</span>
                      </p>
                      <p>
                        Division: <span>{formData.nomination_type}</span>{" "}
                      </p>
                      <div className={styles.editable_field}>
                        {!isEditing ? (
                          <div className={styles.view_input_container}>
                            <p>
                              Budget:{" "}
                              <span>
                                {!formData.newBudget
                                  ? formData.budget
                                  : formData.newBudget}
                              </span>{" "}
                            </p>
                            <button
                              className={styles.save_button}
                              onClick={handleEditClick}
                            >
                              Edit
                            </button>
                          </div>
                        ) : (
                          <div className={styles.view_input_container}>
                            <input
                              type="number"
                              value={formData.newBudget}
                              onChange={handleInputChange}
                              placeholder="Enter New Budget"
                            />
                            <button
                              className={styles.save_button}
                              onClick={handleSaveClick}
                            >
                              Save
                            </button>
                            <button
                              className={styles.save_button}
                              onClick={handleCancelClick}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </div>
                      <p>
                        Estimated clicks:{" "}
                        <span>
                          {!formData.newBudget
                            ? formData.budget * 10
                            : formData.newBudget * 10}
                        </span>
                      </p>
                      <div className={styles.editable_field}>
                       <div className={styles.view_input_container}>
                      <p>
                        Status: <span>{formData.status}</span>  </p>
                        {
                          formData.status === 'Running' ?  <button
                          className={styles.save_button}
                          onClick={()=>handleStatusChange(4020)}
                        >
                          Pause
                    </button> : <button
                          className={styles.save_button}
                          onClick={()=>handleStatusChange(4010)}
                        >
                          Resume
                    </button>
                        }
                      </div></div>
                      {message && <h1> {message}</h1>}
                    </div>
                  </div>
                  <div className={styles.form_col}>
                    <div className={styles.preview_post}>
                      <div className={styles.grid_card}>
                        <div
                          style={{
                           
                          }}
                        >
                          <LazyLoadImage
                            effect="blur"
                            src={DirectusImage(formData?.avatar, 60)}
                            style={{
                              background: "#222",
                              height: "350px",
                              width: "350px"
                            }}
                          />
                          <div className={styles.overlay} />
                          <Card.Content className={styles.content}>
                            <div className={styles.creator_type}>
                            <span>VOTE</span>
                          </div>
                            <Card.Header>
                              <div className={styles.club_details}>
                              </div>
                            </Card.Header>
                          </Card.Content>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="loader" />
          </>
        )}
      </div>
    </div>
  );
}

export default SingleCampaignView;