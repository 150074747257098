import React from "react";
import styles from "../analytics.module.scss";
import Card from "app/components/analytic/analytic";

interface AnalyticsData {
  title: string;
  value: string;
}

interface AnalyticsSectionProps {
  analyticsData: AnalyticsData[];
}

/**
 * Renders the AnalyticsSection component.
 * @returns analytics data such as impressions, clicks, and profile views.
 */
const AnalyticsSection: React.FC<AnalyticsSectionProps> = ({ analyticsData }) => {
  return (
    <div className={styles.analyticsContainer}>
      <h3 className={styles.heading}>Analytics</h3>
      <div className={styles.row}>
        {analyticsData?.map((data, index) => (
          <div className={styles.col} key={index}>
            <Card title={data.title} value={data.value} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnalyticsSection;
