import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { MdEmail, MdDateRange } from 'react-icons/md';
import { FaDollarSign, FaLock } from "react-icons/fa";
import React, { useState } from "react";
import styles from './stripe.module.scss'
import { useBalance } from "app/context/balanceProvider";
import { AdServerTransaction, fetchClientSecret } from "../../ApiActions/api";


function CheckoutForm({ handleSubmit,onClosePayemnt=null}) {
    const {updateBalance} = useBalance()

    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState();
    const [price, setPrice] = useState(1);

    const handleInputChange = (e) => {
        const { value } = e.target;
        setEmail(value);
    }

    const handlePriceChange = (e) => {
        const { value } = e.target;
        if(parseInt(value) < 1) return
        setPrice(value);
    }

    const stripe = useStripe();
    const elements = useElements();

    const SubmitPayment = async () => {
        setLoading(true)
        const client = await fetchClientSecret(price)
        if(client.clientSecret){
         const { error, paymentIntent } = await stripe.confirmCardPayment(client.clientSecret, {
                payment_method: {
                  card: elements.getElement(CardNumberElement),
                },
              });
              if (error) {
                await handleSubmit(false)
              } else if (paymentIntent.status === 'succeeded') {
                  await AdServerTransaction(price)
                  await handleSubmit(true)
                  await updateBalance()
                  if(onClosePayemnt){
                    await onClosePayemnt(true)
                  }
              }
              setLoading(false)
              if(onClosePayemnt){
                await onClosePayemnt(false)
              }
        }
        setLoading(false)
      };

      const handlePayment = async () => {
          await SubmitPayment();
      };
      
    return (
        <div className="payment_card">
            <div className={styles.stripe_card}>
            <div className={styles.input_row}>
                    <div className={styles.input_wrapper_100}>
                        <FaDollarSign />
                        <input type="number" name="price" value={price} placeholder="Credit Amount" onChange={handlePriceChange} className={styles.email_input} />
                    </div>
                </div>
                <div className={styles.input_row}>
                    <div className={styles.input_wrapper_100}>
                        <MdEmail />
                        <input type="email" name="email" value={email} placeholder="Email" onChange={handleInputChange} className={styles.email_input} />
                    </div>
                </div>
                <div className={styles.input_row}>
                    <div className={styles.input_wrapper_100}>

                        <div className={styles.card_Number}>
                            <CardNumberElement options={{ showIcon: true }} />
                        </div>
                    </div>
                </div>
                <div className={styles.input_row_flex}>

                    <div className={styles.input_wrapper}>
                        <MdDateRange />
                        <CardExpiryElement className={styles.cardExpiry} options={{ showIcon: true }} />
                    </div>
                    <div className={styles.input_wrapper}>
                        <FaLock />
                        <CardCvcElement className={styles.cardCvc} options={{ showIcon: true }} />
                    </div>
                </div>
            </div>
            <div className={styles.checkbox_wrapper}>
                <label className="checkbox_label">
                    <input type="checkbox" value="Remember me" name="rememberMe" />
                    <p>
                        Remember me
                    </p>
                </label>

            </div>
                    <div className={styles.checkout_button}>
                {
                    !loading?
                    (<button onClick={handlePayment}>
                    Pay ${price}
                    </button>)
                    :
                    ( <div className="loader btn_loader"></div>)
                }
                    </div>
        </div>

    );
}

export default CheckoutForm;