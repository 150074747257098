import classNames from "classnames";
import styles from "./button.module.scss";
import { FaSpinner } from "react-icons/fa";

const cx = classNames.bind(styles);

interface ButtonProps {
  className?: any;
  size?: "xs" | "sm" | "md" | "lg";
  outline?: boolean;
  children?: any;
  disabled?: boolean;
  hidden?: boolean;
  centered?: boolean;
  onClick?: () => void;
  //loading?: boolean;
}

const Button = (props: ButtonProps) => {
  const { children } = props;
  return (
    <button {...getAttrs(props)}>
      {/* {loading ? <FaSpinner className={styles.spinner} /> : null} */}
      {children}
    </button>
  );
};

export default Button;

/* styles */
export const getAttrs = <T extends ButtonProps>(props: T) => {
  const { size = "sm", outline, ...rest } = props;
  const { ...attrs } = rest;
  const status = { outline, disabled: attrs.disabled };
  const className = cx(styles.button, styles.button_outline, size, status, attrs.className);
  return { ...attrs, className };
};