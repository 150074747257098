import React, { useEffect, useState } from "react";
import styles from "./campaign.module.scss";
import CampaignList from "./CamplaignsList";
import { fetchUserAllCampaigns } from "../ApiActions/api";
import { useHistory } from "react-router-dom";

export default function CampaignView() {
  const history = useHistory();
  const [campaign, setCampaign] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    const data = await fetchUserAllCampaigns();
    setCampaign(data?.campaign);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const newCampaign = () => {
    history.push(`/dashboard/campaigns/new`);
  };

  return (
    <>
      {loading ? (
        <div className="loader" />
      ) : (
        <div className={styles.container}>
          <div className={styles.innerContainer}>
                <p className={styles.heading}>Campaigns</p>
                <div className={styles.create_button}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flex: 1,
                      }}
                    >
                      <button onClick={newCampaign}>
                        Create new campaign{" "}
                      </button>
                    </div>
                  </div>
                  <p className={styles.subHeading}>
                    {" "}
                    {/* <b style={{fontSize: "16px"}}>Note: Campaign creation will be enabled on the 28th of may.</b> */}
                    <br />
                    Campaigns help Artists get 8X more views and clicks on
                    average
                  </p>
                </div>
                <CampaignList campaign={campaign} />
              {/* End of Top Campaigns Container */}
              {/* -------------------------------------------- */}
            </div>
          </div>
      )}
    </>
  );
}
