import styles from './campaign.module.scss'

export default function CampaignProgessBar({ progress }) {
    return (

        <div className={styles.progress_bar_container}>
            <div className={styles.progress_bar} style={{ width: `${progress}%` }}></div>
        </div>

    );
}
