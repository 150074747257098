import React, { useEffect, useState } from "react";
import styles from "../campaign.module.scss";
import CampaignProgessBar from "../CampaignProgress";
import PostCardView from "../PostViewCard/PostCardView";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { DirectusImage, DirectusImageAdServer } from "app/core/common";
import { Card } from "semantic-ui-react";
import { useBalance } from "app/context/balanceProvider";
import { createCampaign, fetchNominations } from "../../ApiActions/api";
import { useHistory } from "react-router-dom";

function NewCampaign() {
  const history = useHistory();
  const { balance } = useBalance();
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState("");
  const [selectedPost, setSelectedPost] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nominationSelect, setNominationSelect] = useState(false);
  const [toast, setToast] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [nomination, setNomination] = useState([]);
  const [progress, setProgress] = useState(33.33);
  const [formData, setFormData] = useState<any>({
    nomination_type: null,
    nomination_name: null,
    nomination_category: null,
    nomination_url: null,
    url_substring: null,
    budget: null,
    first_name: "",
    url: "",
    avatar: "",
    create_date: "",
    display_name: "",
  });

  const fetchNominationsData = async () => {
    setLoading(true);
    const nominationData = await fetchNominations();
    setNomination(nominationData);
    setLoading(false);
  };
  useEffect(() => {
    fetchNominationsData();
  }, []);

  const redirectCampaigns = () => {
    history.push(`/dashboard/campaigns`);
  };

  const handleChangeBudget = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    })
    if (value > balance) {
      setMessage(
        "Your Credits Balance is not sufficient for this campagin budget"
      );     
    } else {
      setMessage("");
    }
  };

  const handleChangeNomination = (nomination) => {
    setFormData({
      ...formData,
      nomination_type: nomination.type,
      nomination_name: nomination.name,
      nomination_category: nomination.category,
      nomination_url: nomination.nomination_url,
      url_substring: nomination.url_substring,
    });
    setMessage("");
    setNominationSelect(true);
  };

  const handlePrev = () => {
    setProgress(progress - 33.33);
    setStep(step - 1);
  };

  const handleSubmitForm = async () => {
    setLoading(true);
    const status = await createCampaign(formData);
    if (status) {
      setToast("New campaign successfully created!");
      setProgress(100);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        redirectCampaigns();
      }, 2000);
    }
    if (!status) {
      setToast(
        "Sorry, your campaign wasn't created. Something went wrong"
      );
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 5000);
    }
    setLoading(false);
  };

  const handleNextStep = () => {
    if (step === 1) {
      if (formData.nomination_type) {
        setProgress(66.66);
        setStep(step + 1);
        setMessage("");
      } else {
        setMessage("Please make sure all field");
      }
    } else if (step === 2) {
      if (formData.budget && selectedPost) {
        setProgress(80);
        setStep(step + 1);
        setMessage("");
      } else {
        setMessage("Please make sure all field");
      }
    } else if (step === 3) {
      handleSubmitForm();
    }
  };

  const handlePost = async (user) => {
    const avatar = await DirectusImageAdServer(user.avatar)
    setFormData({
      ...formData,
      first_name: user.first_name,
      avatar:  avatar,
      display_name: user.display_name,
    });

    setSelectedPost(user);
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
          <h1 className={styles.heading}>New Campaign</h1>
          <div className={styles.form_container}>
            <div className={styles.form}>
              <div className={styles.progress_container}>
                <div className={styles.buttons}>
                  {step !== 1 && progress !== 100 && (
                    <button onClick={handlePrev}>Previous</button>
                  )}
                </div>
                <CampaignProgessBar progress={progress} />
                <div className={styles.buttons}>
                  {step !== 3 && progress !== 100 && (
                    <>
                      {step === 1 ? (
                        <button
                          onClick={handleNextStep}
                          disabled={!nominationSelect}
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          onClick={handleNextStep}
                          disabled={!formData.budget}
                        >
                          Next
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
              {message && (
                <div className={styles.message}>
                  <h1>{message}</h1>
                </div>
              )}
              {showToast && (
                <div className={styles.toast}>
                  <p>{toast}</p>
                </div>
              )}
              {step === 1 && loading ? (
                <div className="loader" />
              ) : (
                step === 1 && (
                  <div className={styles.form_step}>
                    <h1 className={styles.sub_info}>
                      Division:
                      {/* <span style={{ fontWeight: "lighter" }}>( O - 500 followers )</span> */}
                    </h1>
                    <h1 className={styles.sub_info}>
                      Select nomination to promote
                    </h1>
                    <div className={styles.category}>
                      {nomination.map((nomination, index) => (
                        <div
                          key={nomination.id}
                          className={`${styles.post_card} ${
                            formData.nomination_type === nomination.type
                              ? styles.active
                              : ""
                          }`}
                          onClick={() => handleChangeNomination(nomination)}
                        >
                          <div className={styles.category_image_box}>
                            <img
                              src={nomination.image}
                              alt={nomination.name}
                            />
                          </div>
                          <div className={styles.category_text_box}>
                            <h1>{nomination.name}</h1>
                            <p>{nomination.category}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
              {step === 2 && (
                <div className={styles.form_step}>
                  <div className={styles.step_info}>
                    <h1>Set budget</h1>
                    <p>
                      Every time an user clicks on your ad, you will be spending
                      $0.10 of your Ad Credits
                    </p>
                    <h4 className={styles.step_sub_info}>
                      Cost per click - $0.10
                      <br />
                      10 Clicks = $1
                    </h4>
                  </div>
                  <div className={styles.form_col50}>
                    <label>Enter your budget</label>
                    <input
                      type="number"
                      placeholder="Enter Budget here"
                      value={formData.budget}
                      onChange={(e) =>
                        handleChangeBudget("budget", e.target.value)
                      }
                    />
                  </div>
                  <div className={styles.step_info}>
                    <p>Estimated clicks: {formData.budget * 10}</p>
                  </div>
                  <div className={styles.post_grid}>
                    <h1 className={styles.preview_info}>Preview</h1>
                    <PostCardView handlePost={handlePost} />
                  </div>
                </div>
              )}
              {step === 3 && loading ? (
                <div className="loader" />
              ) : (
                step === 3 && (
                  <div className={styles.form_step}>
                    <h1 className={styles.form_step_info}>
                      Confirm your campaign details
                    </h1>
                    <div className={styles.preview_row}>
                      <div className={styles.form_col}>
                        <div className={styles.preview_input}>
                          <p>
                            Ad Type: <span>{formData.nomination_name}</span>
                          </p>
                          <p>
                            Genre: <span>{formData.nomination_category}</span>
                          </p>
                          <p>
                            Division:{" "}
                            <span>{formData.nomination_type}</span>{" "}
                          </p>
                          <p>
                            Budget: <span>{formData.budget}</span>{" "}
                          </p>
                          <p>
                            Estimated clicks:{" "}
                            <span>{formData.budget * 10}</span>
                          </p>
                        </div>
                      </div>
                      <div className={styles.form_col}>
                        <div className={styles.preview_post}>
                          <h1 className={styles.preview_post_info}>
                            Ad Visual Display
                          </h1>
                          <div className={styles.grid_card}>
                            <div
                              style={{
                                background:
                                  "linear-gradient(90deg, #ffd700, #ffa500, #ffd700)",
                                boxShadow:
                                  "0 1px 15px 0 #ffd700, 0 0 10px 1px rgba(0, 0, 0, 0)",
                              }}
                            >
                              <LazyLoadImage
                                effect="blur"
                                src={formData?.avatar}
                                style={{
                                  background: "#222",
                                  height: "350px",
                                  width: "350px"
                                }}
                              />
                              <div className={styles.overlay} />
                              <Card.Content className={styles.content}>
                                {formData?.display_name != "None" && (
                                  <div className={styles.creator_type}>
                                    <span>VOTE</span>
                                  </div>
                                )}
                                <Card.Header>
                                  <div className={styles.club_details}>
                                    <p className={styles.details}>
                                      
                                    </p>
                                  </div>
                                </Card.Header>
                              </Card.Content>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.preview_cal_btn}>
                      <button onClick={handleSubmitForm}>
                        Create New Campaign{" "}
                      </button>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
  );
}

export default NewCampaign;
