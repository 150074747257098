import Cookie from "js-cookie";
import useConnectWallet from "app/Loop_Reuseable_Component/packages/loop_wallet/hooks/useConnectWallet";
import { useUser } from "app/context/userInfo";
import { isCreator } from "app/core/common";

export const useHook = () => {
  const cookie = Cookie.get("cookie");
  const address = Cookie.get("address");
  const {
    userInfo,
    checkingUserInfo,
    followingListLoaded,
    followingList,
    updateFollowingList,
    updateUserInfo,
  } = useUser();
  const _isCreator = isCreator(userInfo?.role);

  return {
    address,
    cookie,
    userInfo,
    checkingUserInfo,
    followingListLoaded,
    followingList,
    isCreator: _isCreator,
    updateFollowingList,
    updateUserInfo,
  };
};
