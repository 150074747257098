import { useEffect, useState } from "react";
import ActionPromptSection from "./ActionPromptSection/ActionPromptSection";
import styles from "./dashboard.module.scss";
import WelcomeSection from "./WelcomeSection/WelcomeSection";
import { fetchUserAllCampaigns } from "../ApiActions/api";

export default function DashboardView() {
  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState({
    balance: null,
    totalAdViews: null,
    totalCampaigns: null,
  });

  const fetchData = async () => {
    const data = await fetchUserAllCampaigns();
    setDashboard({
      balance: `$${data.user?.balances?.advert || 0}`,
      totalAdViews: data.campaign?.reduce(
        (acc, campaign) => acc + parseInt(campaign.counters.impressions),
        0
      ) || 0,
      totalCampaigns: data.campaign?.length || 0,
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const dashboardWelcomeData = [
    {
      title: "Balance",
      value: dashboard.balance,
      icon: "/assets/icons/coins.png",
      caption: "Ad Credits",
    },
    {
      title: "Campaigns",
      value: dashboard.totalCampaigns,
      icon: "/assets/icons/rocket.png",
      caption: "Live",
    },
    {
      title: "Impressions",
      value: dashboard.totalAdViews,
      icon: "/assets/icons/chart.png",
      caption: "Profile Views",
    },
  ];

  const dashboardData = [
    {
      caption: "Top Up Ad Credits",
      icon: "/assets/icons/coins.png",
      redirection: "dashboard/credits",
    },
    {
      caption: "Create New Campaign",
      icon: "/assets/icons/rocket.png",
      redirection: "dashboard/campaigns",
    },
    {
      caption: "View Analytics",
      icon: "/assets/icons/chart.png",
      redirection: "dashboard/analytics",
    },
  ];

  return (
    <>
      {!loading ? (
        <div className={styles.promotionhubs}>
          <WelcomeSection dashboardData={dashboardWelcomeData} />
          <br />
          <ActionPromptSection dashboardData={dashboardData} />
        </div>
      ) : (
        <div className="loader" />
      )}
    </>
  );
}
