import styles from "./analytic.module.scss";

interface AnalyticProps {
  title: string;
  value: any;
}

const Analytic: React.FC<AnalyticProps> = ({ title, value }) => {
  return (
    <div className={styles.analytic}>
      <h3>{value}</h3>
      <p>{title}</p>
    </div>
  );
};

export default Analytic;
