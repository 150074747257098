import { useState } from 'react';
import styles from './campaign.module.scss'
import { Link, useHistory } from 'react-router-dom';
export default function CampaignList({campaign}) {

    const [popoverState, setPopoverState] = useState(null);
    const [activeRow, setActiveRow] = useState(null);

    const history = useHistory();

    const togglePopover = (index) => {
        setPopoverState(popoverState === index ? null : index);
    };

    const handleView = (id) => {
        // Navigate to the route with the specified id
        history.push(`/dashboard/campaign/${id}`);
      };

    return (
        <div className={styles.listContainer}>
            <p className={styles.subHeading}> All Campaigns </p>
            <div className={styles.tableContainer}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Campaign Name</th>
                        <th>Rate</th>
                        <th>Budget</th>
                        <th>Status</th>
                        <th>Launch Date</th>
                        <th>View</th>
                    </tr>
                </thead>
                {campaign?.length > 0 && (
                <tbody>
                    {campaign?.map((row, index) => (
                        <tr key={row.id} onClick={() => handleView(row.id)}
                        className={activeRow === index ? styles.activeRow : ''}
                        onMouseEnter={() => setActiveRow(index)}
                        onMouseLeave={() => setActiveRow(null)}>
                            <td>{row.name}</td>
                            <td>{row.rate}</td>
                            <td>{row.limits?.budget.total}</td>
                            <td>{row.runstatus?.name}</td>
                            <td>{row.created_at}</td>
                            <td>{row.counters?.impressions}</td>
                        </tr>
                    ))}
                </tbody>
                )}
            </table>
              {campaign?.length === 0 &&  (
                    <div className={styles.noDataContainer} style={{ textAlign: "center", marginTop: "50px" }}>
                     <h1 className={styles.content}>
                            No data found.
                     </h1>
                    </div>
               )}
            </div>
        </div>

    );
}