import { useEffect, useState } from "react";
import styles from "./analytics.module.scss";
import AnalyticsSection from "./AnalyticsSection/AnalyticsSection";
import TopCampaignsSection from "./TopCampaignsSection/TopCampainsSection";

import ChartView from "./ChartSection/ChartSection";
import { fetchUserAllCampaigns } from "../ApiActions/api";
/**
 * Renders the AnalyticsView component.
 * This component displays analytics data for ads.
 * it has two sections: Analytics and top campaigns.
 */
export default function AnalyticsView() {
  const [campaign, setCampaign] = useState([]);

  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState({
    dailyAdClicks: null,
    dailyImpressions: null,
    totalAdClicks: null,
    totalImpressions: null,
  });

  const fetchData = async () => {
    try {
      const data = await fetchUserAllCampaigns();
      setDashboard({
        totalAdClicks: data.campaign.reduce(
          (acc, campaign) => acc + parseInt(campaign.counters.clicks, 10),
          0
        ),
        dailyAdClicks: data.campaign.reduce(
          (acc, campaign) => acc + parseInt(campaign.counters.daily_clicks, 10),
          0
        ),
        dailyImpressions: data.campaign.reduce(
          (acc, campaign) =>
            acc + parseInt(campaign.counters.daily_impressions, 10),
          0
        ),
        totalImpressions: data.campaign.reduce(
          (acc, campaign) => acc + parseInt(campaign.counters.impressions, 10),
          0
        ),
      });
      setCampaign(data.campaign);
      setLoading(false);
    } catch (err) {
      // Handle any errors
      setLoading(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const analyticsData = [
    { title: " Impressions", value: dashboard.totalImpressions },
    { title: " Clicks", value: dashboard.totalAdClicks },
    { title: " Daily Clicks", value: dashboard.dailyAdClicks },
  ];

  return (
    <>
      {!loading ? (
        <div className={styles.promotionhubs}>
          <AnalyticsSection analyticsData={analyticsData} />
          <br />
          <ChartView dropdownOptions={campaign} />
          <TopCampaignsSection campaigns={campaign} />
        </div>
      ) : (
        <div className="loader" />
      )}
    </>
  );
}
