import React, { useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import moment from "moment";
import styles from "../analytics.module.scss";
import { useHook } from "app/hooks/common";
import axios from "axios";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ChartView = ({ dropdownOptions }) => {
  const { address, cookie } = useHook();

  const [selectedOption, setSelectedOption] = useState({
    id: null,
    name: "",
    clicks: 1,
    impressions: 1,
  });
  const [selectedOptionDate, setSelectedOptionDate] = useState("Today");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  // Function to handle option change
  const handleOptionChange = (optionDate) => {
    setSelectedOptionDate(optionDate);
    const today = moment();
    switch (optionDate) {
      case "Today":
        setSelectedStartDate(today);
        setSelectedEndDate(today);
        break;
      case "Last Week":
        setSelectedStartDate(today.clone().subtract(1, "weeks"));
        setSelectedEndDate(today);
        break;
      case "Last Month":
        setSelectedStartDate(today.clone().subtract(1, "months"));
        setSelectedEndDate(today);
        break;
      case "Last Year":
        setSelectedStartDate(today.clone().subtract(1, "years"));
        setSelectedEndDate(today);
        break;
      default:
        setSelectedStartDate(null);
        setSelectedEndDate(null);
        break;
    }
  };

  const optionsDate = ["Today", "Last Week", "Last Month", "Last Year"];

  const fatchUsercampaign = async (campaignId) => {
    if(cookie){
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_MIDDLEWARE}adServer/stats/campaign`,
        data: {
          cookie: cookie,
          data: {
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            campaign: campaignId,
          },
        },
      });

      const res = response.data;
      if (res.length === 0) {
        const campaign = await dropdownOptions.find(
          (campaign) => campaign.id == campaignId
        );
        setSelectedOption({
          id: campaign.id,
          name: campaign.name,
          clicks: parseInt(campaign.counters.clicks),
          impressions: parseInt(campaign.counters.impression),
        });
      } else {
        setSelectedOption({
          id: res[0].iddimension,
          name: res[0].dimension,
          clicks: res[0].clicks || 0,
          impressions: res[0].impressions || 0,
        });
      }
    }
  };
  const fatchUserStats = async () => {
    if(cookie){
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_MIDDLEWARE}adServer/stats`,
        data: {
          cookie: cookie,
        },
      });

      const res = response.data;
      if (res.length === 0) {
        setSelectedOption({
          ...selectedOption,
          name: "",
          clicks: 0,
          impressions: 0,
        });
      } else {
        setSelectedOption({
          id: res[0].iddimension,
          name: res[0].dimension,
          clicks: res[0].clicks || 0,
          impressions: res[0].impressions || 0,
        });
      }
    }
  };
  useEffect(() => {
    fatchUserStats();
  }, []);

  const handleChange = async (event) => {
    const selectedValue = event.target.value;
    const res = await fatchUsercampaign(selectedValue);
  };

  const options = {
    zoomEnabled: true,
    panEnabled: true,
    animationEnabled: true,
    theme: "dark1",
    backgroundColor: "2d2d2f",
    legend: {
      cursor: "pointer",
    },
    data: [
      {
        type: "column",
        name: "Clicks",
        showInLegend: true,
        indexLabel: "{y}",
        indexLabelFontColor: "#31a2fa",
        color: "#31a2fa",
        dataPoints: [{ label: "Clicks", y: selectedOption.clicks || 0 }],
      },
      {
        type: "column",
        name: "Impression",
        showInLegend: true,
        indexLabel: "{y}",
        indexLabelFontColor: "#9b83f9",
        color: "#9b83f9",
        dataPoints: [{ label: "Impression", y: selectedOption.impressions || 0 }],
      },
    ],
  };

  return (
    <div className={styles.chart_container}>
      <div className={styles.dropdown_container}>
        <select
          className={styles.dropdown}
          onChange={handleChange}
          value={selectedOption.id}
        >
          <option value="" className={styles.dropdown_option}>
            Select Campaign
          </option>
          {dropdownOptions.map((campaign) => (
            <option
              key={campaign.id}
              value={campaign.id}
              className={styles.dropdown_option}
            >
              {campaign.name}
            </option>
          ))}
        </select>
        <select
          className={styles.dropdown}
          value={selectedOptionDate}
          onChange={(e) => handleOptionChange(e.target.value)}
        >
          {optionsDate.map((option) => (
            <option
              key={option}
              value={option}
              className={styles.dropdown_option}
            >
              {option}
            </option>
          ))}
        </select>
        {selectedStartDate && selectedEndDate && (
          <div>
            <p>Selected Date Range:</p>
            <p>
              {selectedStartDate.format("MM/DD/YYYY")} -{" "}
              {selectedEndDate.format("MM/DD/YYYY")}
            </p>
          </div>
        )}
      </div>
      <CanvasJSChart options={options} width="100%" />
    </div>
  );
};
export default ChartView;