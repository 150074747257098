import React from "react";
import styles from "../dashboard.module.scss";
import Card from "app/components/dashboard/card";
import { Link } from "react-router-dom";

interface DashboardData {
  icon: string;
  caption: string;
  redirection: string
}

interface AnalyticsSectionProps {
  dashboardData: DashboardData[];
}

/**
 * Renders the ActionPromptSection component.
 * @returns dashboard data such as impressions, clicks, and profile views.
 */
const ActionPromptSection: React.FC<AnalyticsSectionProps> = ({ dashboardData }) => {
  return (
    <div className={styles.dashboardContainer}>
      <h3 className={styles.subHeading}>What would you like to do?</h3>
      <div className={styles.row}>
        {dashboardData?.map((data, index) => (
          <div className={`${styles.col}  ${styles.link_card1} `} key={index}>
            <Link to={data.redirection}>
            <Card icon={data.icon} caption={data.caption} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActionPromptSection;