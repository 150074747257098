import { useEffect, useState } from "react";
import * as api from "../../api/user.api";
import { useWallet } from "../../Loop_Reuseable_Component/packages/loop_wallet";
import TimeAgo from "app/helpers/timeago";
import { FeedObject, NFT } from "app/core/common";
import { useHook } from "app/hooks/common";

const useUserApi = () => {
  const { address, cookie } = useHook();
  const  [checkingUserInfo, setCheckingUserInfo] = useState(true);
  const [userInfo, setUserInfo] = useState();
  const [followingListLoaded, setFollowingListLoaded] = useState(false);
  const [followingList, setFollowingList] = useState([]);

  const [feed, setFeed] = useState([]);
  const [feedMeta, setFeedMeta] = useState<any>();
  const [myNFTsLoading, setMyNFTsLoading] = useState(true);

  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [myNfts, setMyNfts] = useState<NFT[]>([]);
  const [page, setPage] = useState(1);
  const [nftPagination, setNftPagination] = useState<any>();

  const updateUserInfo = async () => {
    console.log("Updating User Info");
    if (address && cookie) {
      const [userInfo] = await api.fetchUserData({ cookie, address });
      console.log({userInfo})
      setUserInfo(userInfo);
    }
  }

  /* ======================== */
  /* ==== Fetch User Data ==== */
  /* ======================== */
  useEffect(() => {
    const fetchUserData = async () => {
      if (address && cookie) {
        return api.fetchUserData({ cookie, address });
      }
    };
  
    const fetchAllData = async () => {
      if (address && cookie) {
        const [userInfo] = await Promise.all([fetchUserData()]);
        setUserInfo(userInfo[0]);
        setCheckingUserInfo(false);
        setFollowingList(followingList);
        setFollowingListLoaded(true);
      } else {
        setCheckingUserInfo(false);
      }
    };
  
    fetchAllData();
  }, [address, cookie]);

  /**************************************/
  // Fetch More NFTs
  /**************************************/
  const loadMoreNFTs = () => {
    const totalPages = nftPagination?.total_pages > nftPagination?.current_page;
    //console.log("Load More", totalPages);
    if (totalPages) {
      setMyNFTsLoading(true);
      setPage(page + 1); // Increment page number
    }
  };

  return {
    userInfo,
    checkingUserInfo,
    followingList,
    followingListLoaded,
    lastUpdated,
    updateFollowingList: setFollowingList,
    updateUserInfo: updateUserInfo,
  };
};
export default useUserApi;
