import React from "react";
import { DirectusImage } from "app/core/common";
import axios from "axios";
import Cookie from "js-cookie";

const cookie = Cookie.get("cookie");
const address = Cookie.get("address");
const BACKEND_MIDDLEWARE = process.env.REACT_APP_MIDDLEWARE;

export const getUserInfo = async () => {
  try {
    if(cookie){
      const response = await axios({
        method: "post",
        url: `${BACKEND_MIDDLEWARE}user/userInfo`,
        data: {
          address: address,
          cookie: cookie,
        },
      });
      const user = response.data;
      return user;
    }
  } catch (error) {
    console.log("Error in userInfo", error);
    return false;
  }
};

export const fetchUserAllCampaigns = async () => {
  try {
    if(cookie){
      const response = await axios({
        method: "post",
        url: `${BACKEND_MIDDLEWARE}adServer/user`,
        data: {
          cookie: cookie,
        },
      });
      const data = response.data;
      return data;
    }
  } catch (error) {
    console.log("Error in fetchALlCampaigns", error);
    return false;
  }
};

export const createCampaign = async (formdata: any) => {
  try {
    if(cookie){
      const response = await axios.post(
        `${BACKEND_MIDDLEWARE}adServer/campaign`,
        {
          cookie: cookie,
          data: formdata,
        }
      );
      if (response.data) {
        return true;
      }
      return false;
    }
  } catch (error) {
    console.log("Error in create campaign", error);
    return false;
  }
};

export const fetchNominations = async () => {
  try {
    if(cookie){
      const response = await axios.get(
        "https://hammerhead-app-dukeb.ondigitalocean.app/v1/arena/leaderboard/me",
        {
          headers: {
            user_cookie: cookie,
          },
        }
      );
      const data = response.data;
      const transformedData = data.map((item) => ({
        id: item.leaderboard.id,
        name: item.launchpad.name,
        category: item.leaderboard.genre.name,
        image: DirectusImage(item.launchpad.banner.id, 60),
        type: item.leaderboard.division.name,
        nomination_url: item.launchpad.id,
        url_substring: `wma/list?genre=${item.leaderboard.genre.id}&division=${item.leaderboard.division.id}`
      }));
      return transformedData;
    }
  } catch (error) {
    console.error("Error fetching nominations:", error);
    return error;
  }
};

export const AdServerTransaction = async (price) => {
  try {
    if(cookie){
      const response = await axios.post(
        `${BACKEND_MIDDLEWARE}AdServer/create-transaction`,
        {
          cookie: cookie,
          data: {
            amount: price,
          },
        }
      );
      return response;
    }
  } catch (error) {
    console.error("Error fetching nominations:", error);
    return error;
  }
};

export const fetchTransaction = async (price) => {
  try {
    if(cookie){
      const response = await axios.post(
        `${BACKEND_MIDDLEWARE}adServer/transaction`,
        {
          cookie,
          address,
        }
      );
      return response;
    }
  } catch (error) {
    console.error("Error fetching nominations:", error);
    return error;
  }
};

export const fetchClientSecret = async (price) => {
  try {
    if(cookie){
      const response = await axios.post(
        `${BACKEND_MIDDLEWARE}stripe/create-payment-intent`,
        {
          cookie: cookie,
          data: {
            amount: price, // Amount in cents
          },
        }
      );

      const data = await response.data;
      return data;
    }
  } catch (err) {
    console.log("Err in client secret: ", err);
    return false;
  }
};
