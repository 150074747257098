import "./App.scss";
import Sidebar from "./sidebar/Sidebar";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import LoginPage from "app/pages/Login/Login";
import Profile from "app/pages/Profiles/Profile";
import { NotificationContext } from "app/context/notification";
import { useCallback, useEffect, useRef, useState } from "react";
import NotificationModals from "app/components/notificationModals";
import ClubView from "app/pages/Clubs/ClubView";
import ScrollToTop from "ScrollToTop";
import { useHook } from "app/hooks/common";
import LoginModal from "app/components/loginModal/LoginModal";
import { useLogin } from "app/context/loginProvider";
import DashboardView from "app/pages/PromotionHub/Dashboard/DashboardView";
import PaymentsView from "app/pages/PromotionHub/Payments/PaymentsView";
import AnalyticsView from "app/pages/PromotionHub/Analytics/AnalyticsView";
import CampaignView from "app/pages/PromotionHub/Campaigns/CampaignView";
import SingleCampaignView from "app/pages/PromotionHub/Campaigns/SingleCampaignView/SingleCampaignView";
import LoginRoute from "app/pages/Login/LoginPage";
import { BalanceContextProvider } from "app/context/balanceProvider";
import ZoneView from "app/pages/PromotionHub/Zone/ZoneView";
import NewCampaign from "app/pages/PromotionHub/Campaigns/NewCampaign/NewCampaign";

/* interface BugpilotOptions {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  subscriptionStatus?: string;
} */

/* declare global {
  interface Window {
    Bugpilot: {
      identify: (options: BugpilotOptions) => void;
      logout: () => void;
    };
  }
} */

function App() {
  const { address, cookie } = useHook();
  const [type, setType] = useState(undefined);
  const [subType, setSubType] = useState(undefined);
  const [txHash, setTxHash] = useState(undefined);
  const [onOk, setOnOk] = useState(undefined);
  const [txErrorMessage, setTxErrorMessage] = useState(undefined);
  const [redirectURL, setRedirectURL] = useState("");
  const [themeData, setThemeData] = useState<any>({ theme: {} });
  const location = useLocation();

  const setTheme = useCallback((theme) => {
    setThemeData({ theme: theme });
    setType(theme?.type);
    setSubType(theme?.subType);
    setTxHash(theme?.txHash);
    setOnOk(theme?.onOk);
    setTxErrorMessage(theme?.txErrorMessage);
    if (theme?.redirectURL !== undefined) {
      setRedirectURL(theme?.redirectURL);
    }
  }, []);

  /* useEffect(() => {
    if (window.Bugpilot && userInfo) {
      window.Bugpilot.identify({
        id: userInfo.id,
        firstName: userInfo.first_name,
        subscriptionStatus: "active",
      });
    }
  }, [userInfo]); */

  const [state, setState] = useState<any>({
    theme: {
      type: undefined,
      subType: undefined,
      txHash: undefined,
      onOk: undefined,
      txErrorMessage: undefined,
      redirectURL: undefined,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setTheme,
  });
  useEffect(() => {
    setState((prevState: any) => ({
      ...prevState,
      theme: {
        type,
        subType,
        txHash,
        onOk,
        txErrorMessage,
        redirectURL,
        ...themeData.theme,
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setTheme,
    }));
  }, [
    type,
    subType,
    txHash,
    onOk,
    txErrorMessage,
    redirectURL,
    setTheme,
    themeData.theme,
  ]);

  useEffect(() => {
    const handleResize = () => {
      const isKeyboardOpen = window.innerHeight < window.screen.height;

      if (isKeyboardOpen) {
        document.body.style.height = "100vh";
        // Other styling adjustments as needed
      } else {
        document.body.style.height = "auto";
        // Reset other styles
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const containerRef = useRef(null);
  const { showLoginModal, showSignup, artistSignup } = useLogin();

  return (
    <div className="App">
      <ScrollToTop />
      <NotificationContext.Provider value={state}>
        <NotificationModals />
        <LoginModal
          showLoginModal={showLoginModal}
          showSignup={showSignup}
          artistSignup={artistSignup}
        />
       <BalanceContextProvider>   
        <Switch>
          {/* Sidebar with Routes */}
          <Route>
            <Sidebar isLoggedIn={address} containerRef={containerRef}>
              {/* Login Route */}
              <Route path="/login/" component={LoginPage} exact />
              <Route
                path="/"
                render={() => (address ? <DashboardView /> : <Redirect to="/login" />)}
                exact
              />
              <Route
                path="/dashboard/campaigns"
                render={() => (address ? <CampaignView /> : <Redirect to="/login" />)}
                exact
              />
              <Route
                  path="/dashboard/campaigns/new"
                  render={() =>
                    address ? <NewCampaign /> : <Redirect to="/login" />
                  }
                  exact
              />
               <Route
                path="/dashboard/campaign/:id"
                render={({ match }) => ( address ? <SingleCampaignView id={match.params.id} /> : <Redirect to="/login" /> )}
                exact
              />
              <Route
                path="/dashboard/analytics"
                render={() => (address ? <AnalyticsView /> : <Redirect to="/login" />)}
                exact
              />
              <Route
                path="/dashboard/credits"
                render={() => (address ? <PaymentsView /> : <Redirect to="/login" />)}
                exact
              />
              <Route
                  path="/dashboard/zone"
                  render={() =>
                    address ? <ZoneView /> : <Redirect to="/login" />
                  }
                  exact
              />
            </Sidebar>
          </Route>
        </Switch>
        </BalanceContextProvider>
      </NotificationContext.Provider>
    </div>
  );
}

export default App;
