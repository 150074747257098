import { useEffect, useState } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import styles from "./stripe.module.scss";
import avatar from "../../../../../assets/logo.png";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

export default function StripePayment({
  onClosePayment,
  handleSubmit,
}) {
  const stripePromise = loadStripe(
    "pk_test_51O7PaIFxjSYqlVxIZocBPSrr0fizbcXJHeqoMiynMpHTVZOwvlbHWN9xgn03QTCzSZQh0ZLKnGNdQ6HCM2THq8Gt00eoBdcobu"
  );
  return (
    <div className={styles.payment_card}>
      <div className={styles.popup_overlay}>
        <div className={styles.popup}>
          <div className={styles.popup_head}>
            <button className={styles.close_btn} onClick={onClosePayment}>
              X
            </button>
            <img src={avatar} className={styles.logo} />

            <h1 className={styles.heading}>Loop Fans</h1>
            <p className={styles.subheading}>Credits Payment</p>
          </div>
          <div className={styles.popup_content}>
            <Elements stripe={stripePromise}>
              <CheckoutForm
                onClosePayemnt={onClosePayment}
                handleSubmit={handleSubmit}
              />
            </Elements>
          </div>
        </div>
      </div>
    </div>
  );
}