import { useEffect, useState } from "react";
import styles from "./postView.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { DirectusImage, DirectusImageAdServer } from "app/core/common";
import { Card } from "semantic-ui-react";
import { getUserInfo } from "../../ApiActions/api";
import { useHook } from "app/hooks/common";

export default function PostCardView({ handlePost }) {
  const { userInfo } = useHook();
  const [creators, setCreators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleCardClick = async (index, creator) => {
    setActiveIndex(index);
    await handlePost(creator);
  };

  async function fetchData() {
    try {
      setLoading(true);
      setCreators([userInfo]);
      await handlePost(userInfo);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (creators.length > 0) {
      handleCardClick(0, creators[0]);
    }
  }, [creators]);

  return (
    loading? 
    <div className="loader" /> :
    <div className={styles.clubs}>
      <div className={styles.container}>
        {creators.length > 0 && (
          <div>
            <div className={styles.club}>
              {creators?.map((creator, index) => {
                return (
                  <div
                    style={{
                      // cursor: "pointer",
                      // border: index === activeIndex ? "2px solid #FFC107" : "none",
                      background:
                        index === activeIndex
                          ? "linear-gradient(90deg, #ffd700, #ffa500, #ffd700)"
                          : "",
                      boxShadow:
                        index === activeIndex
                          ? "0 1px 15px 0 #ffd700, 0 0 10px 1px rgba(0, 0, 0, 0)"
                          : "none",
                    }}
                    onClick={() => handleCardClick(index, creator)}
                    //to={`/user/${creator?.username ?? creator?.id}/collections`}
                    className={styles.item}
                    key={index}
                  >
                    <LazyLoadImage
                      alt={`${creator.id}`}
                      effect="blur"
                      src={DirectusImage(creator?.avatar,60)}
                      style={{
                        background: index === activeIndex ? "#222" : "",
                        height: "350px",
                        width: "350px"
                      }}
                    />
                    <div className={styles.overlay} />
                    <Card.Content className={styles.content}>
                      {creator?.display_name != "None" && (
                        <div className={styles.creator_type}>
                          <span>VOTE</span>
                        </div>
                      )}
                      <Card.Header
                        className={creator.disabled && styles.disabled}
                      >
                        <div className={styles.club_details}>
                          <p className={styles.details}>
                          </p>
                        </div>
                      </Card.Header>
                    </Card.Content>
                  </div>
                );
              })}
              <div
                //to={`/user/${creator?.username ?? creator?.id}/collections`}
                className={styles.item}
                key={1}
              >
                <div className={styles.overlay} />
              </div>
              <div
                //to={`/user/${creator?.username ?? creator?.id}/collections`}
                className={styles.item}
                key={2}
              >
                <div className={styles.overlay} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
