import axios from "axios";

export async function FetchFeed({ userInfo, ids, page }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/feed`,
    method: "post",
    data: {
      userInfo: userInfo,
      ids: ids,
      page: page
    },
  });

  return result.data;
}

export async function FetchByDate({ cookie, ids, lastDate, userInfo }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/fetchByDate`,
    method: "post",
    data: {
      cookie: cookie,
      ids: ids,
      lastDate: lastDate,
      userInfo: userInfo
    },
  });

  return result.data;
}

export async function FetchComments({ post_id}) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/comments`,
    method: "post",
    data: {
      post_id: post_id
    },
  });

  return result.data;
}

export async function fetchUserData({ cookie, address }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}user/userInfo`,
    method: "post",
    data: {
      cookie: cookie,
      address: address,
    },
  });

  return result.data;
}

export async function fetchUserFollowing({ cookie, userInfo }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}user/following`,
    method: "post",
    data: {
      cookie: cookie,
      userInfo: userInfo,
    },
  });

  return result.data;
}
