import React, { useState } from "react";
import styles from "../payments.module.scss";
import StripePayment from "../PaymentCard/StripePaymentCard";

/**
 * Renders the credit balance section of the Promotion Hub page.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.totalPurchasedCredits - The total number of purchased credits.
 * @param {number} props.totalUsedCredits - The total number of used credits.
 * @param {number} props.balanceCredits - The balance credits.
 * @returns {JSX.Element} The rendered component.
 */
const CreditBalanceSection = ({ refetchInvoice, balance = 0 }) => {
  const [pay, setPay] = useState(false);
  const [toast, setToast] = useState("");
  const [showToast, setShowToast] = useState(false);

  const showPay = () => {
    setPay(true);
  };

  const onClose = (status) => {
    setPay(false);
  };

  const onSubmit = (status) => {
    setPay(false);
    toastTrigger(status)
  };

  const toastTrigger = (status)=>{
    refetchInvoice();
    if(status){
        setToast("Payment successfully processed!");
    }
    if(!status){
        setToast("Payment process failed!");
    }
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
}
  return (
    <div className={styles.creditBalanceContainer}>
      <h3 className={styles.subHeading}></h3>
      <div className={styles.row}>
        <div className={styles.row}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flex: 2,
            }}
          >
            <img
              src="/assets/icons/coins.png"
              style={{ width: "45px", height: "45px", marginRight: "5px" }}
              alt="coins"
            />
            <b style={{ color: "#c1c1c3", fontSize: "large" }}>
              Ad Credits: ${balance}
            </b>
          </div>
          {showToast && (
                <div className={styles.toast}>
                  <p>{toast}</p>
                </div>
              )}
          <div className={styles.preview_cal_btn}>
            {pay && (
              <StripePayment
                onClosePayment={onClose}
                handleSubmit={onSubmit}
              />
            )}
            {/* <div className={styles.confirm_button}>
              <button onClick={showPay}>Top Up Credits </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditBalanceSection;