import styles from "./dashboard.module.scss";

interface WelcomeCardProps {
  title: string;
  value: string;
  icon: string;
  caption: string;
}

const WelcomeCard: React.FC<WelcomeCardProps> = ({
  title,
  value,
  icon,
  caption,
}) => {
  return (
    <div className={styles.welcomeCard}>
      <div className={styles.titleContainer}>
        <p className={styles.title}>{title}</p>
        <img src={icon} alt="icon" />
      </div>
      <div className={styles.valueContainer}>
        <h3 className={styles.value}>{value}</h3>
        <p className={styles.caption}>{caption}</p>
      </div>
    </div>
  );
};

export default WelcomeCard;
