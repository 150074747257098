import { useEffect, useState } from "react";
import styles from "./zone.module.scss";
import Loader from "app/components/Loader";

export default function ZoneView() {
  const iframeStyle = {
    width: "300px",
    height: "300px",
    overflow: "hidden",
    border: "none",
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.zonehubs}>
          <div className={styles.heading}>Zone Preview</div>
          <div className={styles.ads_main}>
            <div className={styles.codeContainer}>
              <iframe
                src="https://media.aso1.net/js/ifr.html#id=117491"
                width="300px"
                height="300px"
                frameBorder="0"
                marginHeight={0}
                marginWidth={0}
                scrolling="no"
                style={iframeStyle}
              ></iframe>
            </div>
            <div className={styles.codeContainer}>
              <iframe
                src="https://media.aso1.net/js/ifr.html#id=117491"
                width="200px"
                height="200px"
                frameBorder="0"
                marginHeight={0}
                marginWidth={0}
                scrolling="no"
                style={iframeStyle}
              ></iframe>
            </div>
            <div className={styles.codeContainer}>
              <iframe
                src="https://media.aso1.net/js/ifr.html#id=117491"
                width="200px"
                height="200px"
                frameBorder="0"
                marginHeight={0}
                marginWidth={0}
                scrolling="no"
                style={iframeStyle}
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </>
  );
}