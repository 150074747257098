import { useEffect, useState } from "react";
import CreditBalanceSection from "./CreditBalanceSection/CreditBalanceSection";
import PaymentTableSection from "./PaymentTableSection/PaymentTableSection";
import styles from "./payments.module.scss";
import axios from "axios";
import { useHook } from "app/hooks/common";
import Loader from "app/components/Loader";
import { useBalance } from "app/context/balanceProvider";

interface IPaymentTable {
  invoiceId: string;
  amount: string;
  status: string;
  date: string;
}

/**
 * @constant {paymentTable[]} paymentTable - An array of credit histories.
 * @constant {Object} creditBalanceData - An object containing credit balance data.
 * @returns The rendered PaymentsView component.
 */
export default function PaymentsView() {
  const { balance } = useBalance();
  const [loading, setLoading] = useState(true);
  const { address, cookie } = useHook();
  const [transactions, setTransactions] = useState<IPaymentTable[]>([]);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      if(cookie){
        const resp = await axios.post(
          `${process.env.REACT_APP_MIDDLEWARE}adServer/transaction`,
          {
            cookie,
            address,
          }
        );
        let transactions: IPaymentTable[] = [];
        if (resp.data && resp.data.length) {
          resp.data.forEach((rec) => {
            transactions.push({
              amount: rec.amount,
              date: new Date(rec.created_at).toLocaleDateString(),
              invoiceId: rec.id,
              status: "Paid",
            });
          });
          setTransactions(transactions);
          setLoading(false);
        }
        setLoading(false);
      }
    } catch (err) {
      console.log(`Error in fecthing transactions:  ${err}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.promotionhubs}>
          <div className={styles.heading}>Credits</div>
          <CreditBalanceSection
            balance={balance}
            refetchInvoice={fetchTransactions}
          />
          <PaymentTableSection PaymentTableData={transactions} />
        </div>
      )}
    </>
  );
}
