import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import logo from "../assets/logo.svg";
import SearchDrawer from "./SearchDrawer";
import NotiDrawer from "./NotiDrawer";
import { useWallet } from "app/Loop_Reuseable_Component/packages/loop_wallet";
import { useHook } from "app/hooks/common";
import LoginModal from "app/components/loginModal/LoginModal";
import { Trans } from "react-i18next";
import LanguageSelector from "app/util/LanguageSelector";
import Header from "header/Header";
import axios from "axios";
import { useBalance } from "app/context/balanceProvider";
import { IoIosMenu, IoIosCloseCircleOutline } from "react-icons/io";
import { MdOutlineAddCircle } from "react-icons/md";
import { IoMdArrowDropright } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoRocketSharp } from "react-icons/io5";

export default function Sidebar({
  isLoggedIn,
  children,
  containerRef,
}: {
  isLoggedIn: boolean;
  children: any;
  containerRef: any;
}) {
  const { logout } = useWallet();
  const { userInfo, address, cookie } = useHook();
  const location = useLocation();
  const [notiDrawer, setNotiDrawer] = useState(false);
  const [searchDrawer, setSearchDrawer] = useState(false);
  const [noheader, setNoHeader] = useState(false);
  const { balance } = useBalance();

  const [adServerUser, setAdServerUser] = useState<any>({});
  const [loading, setLoading] = useState(true);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const origin = window.location.origin;
  const pathname = window.location.pathname;

  const promotionHubNav = [
    {
      name: "Ads Manager",
      icon: origin + "/assets/icons/home",
      to:'/',
      auth: true,
      type: "true",
      sub_menu: [
        {
          name: "Campaigns",
          to: "/dashboard/campaigns",
          icon: origin + "/assets/icons/home",
          auth: true,
          type: "page",
        },
        {
          name: "Analytics",
          to: "/dashboard/analytics",
          icon: origin + "/assets/icons/home",
          auth: true,
          type: "page",
        },
        {
          name: "Credits",
          to: "/dashboard/credits",
          icon: origin + "/assets/icons/home",
          auth: true,
          type: "page",
        },
        /* {
          name: "Zone",
          to: "/dashboard/zone",
          icon: origin + "/assets/icons/home",
          auth: true,
          type: "page",
        }, */
      ],
    },
  ];
  
  
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_MIDDLEWARE}adServer/user`,
        data: {
          cookie: cookie,
        },
      });

      const data = await response.data;
      setAdServerUser(data?.user);
      setLoading(false);
    } catch (err) {
      // Handle any errors
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header noheader={noheader} />
      <div
        className={`${styles.sidebar} container`}
        style={notiDrawer || searchDrawer ? { overflow: "hidden" } : {}}
      >
        {!pathname.includes("/login") && (
          <div
            className={
              notiDrawer || searchDrawer
                ? `${styles.sidebarCollapse} ${styles.sidebarContent}`
                : styles.sidebarContent
            }
          >
            {/* Search */}
            <SearchDrawer show={searchDrawer} setShow={setSearchDrawer} />

            {/* Notifications */}
            <NotiDrawer show={notiDrawer} setShow={setNotiDrawer} />

            <div
              className={
                userInfo
                  ? styles.navbar
                  : `${styles.navbar} ${styles.navbar_hm}`
              }
            >
              {/* <div className={styles.logo_section}>
                <img src={logo} className={styles.logo} alt="logo" />
                <span>Arena</span>
              </div>
 */}
              <ul>
              {isLoggedIn && (
                  <li
                   className={styles.navBar_credits}
                  >
                    <img
                      src="/assets/icons/coins.png"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      alt="coins"
                    />
                    <b style={{ color: "#c1c1c3" }}>Ad Credits: ${balance ? balance :0}</b>
                    <Link
                      to="/dashboard/credits"
                      style={{ display: "flex" }}
                    >
                      <MdOutlineAddCircle size={25} style={{color: "#c1c1c3", fill: "#c1c1c3"}}/>
                    </Link>
                  </li>
                )}
                {/* {isLoggedIn && (
                  <li
                    className={location.pathname === "/" ? styles.active : ""}
                  >
                    <Link
                      to={"/"}
                      onClick={() => {
                        setSearchDrawer(false);
                        setNotiDrawer(false);
                      }}
                    >
                      <img
                        alt={"home"}
                        src={
                          location.pathname === "/"
                            ? `/assets/icons/home_active.svg`
                            : `/assets/icons/home.svg`
                        }
                      />
                      <span>Feed</span>
                    </Link>
                  </li>
                )} */}

                {/* Promotion Hub Navbar */}
                {promotionHubNav.map((item: any, index: number) => {
                  return (
                    <li
                      className={styles.active}
                      key={`item-${index}`}
                      hidden={item.auth ? !Boolean(userInfo) : false}
                    >
                      <Link className={styles.mainItem}
                        to={item.to}
                        onClick={() => {
                          if (item.type === "search") {
                            notiDrawer && setNotiDrawer(false);
                            setSearchDrawer(!searchDrawer);
                          } else if (item.type === "notifications") {
                            searchDrawer && setSearchDrawer(false);
                            setNotiDrawer(!notiDrawer);
                          } else {
                            setSearchDrawer(false);
                            setNotiDrawer(false);
                          }
                        }}
                      >
                       {/* <img
                              src="/assets/icons/rocket.png"
                              alt={item.name}
                              width={25}
                            /> */}
                        <IoRocketSharp size={25} style={{fill: isActive ? "#9b83f9": "#c1c1c3"}} 
                          
                      />
                        <span>{item.name}</span>
                        <div className={styles.menuDropDown}>
                        {isActive ? <IoMdArrowDropdown size={20}  onClick={()=>{setIsActive(!isActive)}}/> : <IoMdArrowDropright size={20}
                          onClick={()=>{setIsActive(!isActive)}}
                      /> }
                        </div>
                      </Link>
                      {item.sub_menu && (
                        <ul className={isActive ? styles.sub_menu : styles.sub_menu_hide}>
                          {item.sub_menu.map(
                            (subItem: any, subIndex: number) => {
                              const subIsActive =
                                location.pathname === `${subItem.to}`;
                              return (
                                <li
                                  className={subIsActive ? styles.active_sub : ""}
                                  style={{
                                    padding: "5px 30px",
                                    margin: "10px 15px",
                                  }}
                                  key={`subItem-${subIndex}`}
                                  hidden={
                                    subItem.auth ? !Boolean(userInfo) : false
                                  }
                                >
                                  <Link
                                    to={`${subItem.to}`}
                                    onClick={() => {
                                      if (subItem.type === "search") {
                                        notiDrawer && setNotiDrawer(false);
                                        setSearchDrawer(!searchDrawer);
                                      } else if (
                                        subItem.type === "notifications"
                                      ) {
                                        searchDrawer && setSearchDrawer(false);
                                        setNotiDrawer(!notiDrawer);
                                      } else {
                                        setSearchDrawer(false);
                                        setNotiDrawer(false);
                                      }
                                    }}
                                  >
                                    <span>{subItem.name}</span>
                                  </Link>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      )}
                    </li>
                  );
                })}

                {/* User Navbar */}
                <li className={styles.mobileHumburgerMenu} onClick={()=>setShowMobileMenu(!showMobileMenu)}><IoIosMenu size={35}/>
                  <ul className={showMobileMenu ? styles.mobileMenuActive : styles.mobileMenu }>
                  <div className={styles.linksWapper}>
                      <div className={styles.menuHeading}>
                       <h4 className={styles.headdding} style={{textTransform:"uppercase"}}>menu</h4>
                       <p className={styles.closeIcon}><IoIosCloseCircleOutline  size={30} color="fff"/></p>
                        </div>
                          {promotionHubNav[0].sub_menu.map(
                            (item: any, subIndex: number) => {
                              const subIsActive =
                                location.pathname == item.to;
                               return (
                                <li
                                  className={subIsActive ? styles.active_sub : ""}
                                  key={`subItem-${subIndex}`}
                                >
                                 <Link to={item.to}>
                                  {item.name}
                                 </Link>
                                </li>

                              );

                            }
                          )}
                          </div>
                        </ul>
                  </li>
              </ul>

              <div className={styles.footer}>
                {/* <LanguageSelector /> */}
                {/* {isLoggedIn ? (
                  <Button
                    outline={false}
                    onClick={() => {
                      logout();
                      window.location.reload();
                    }}
                  >
                    <img alt="logout" src={`/assets/icons/logout.svg`} />
                    <span>Logout</span>
                  </Button>
                ) : (
                  <Button onClick={() => setShowLoginModal(true)}>Login</Button>
                )} */}
                {/* {!isLoggedIn && <Button onClick={() => setShowLoginModal(true)}>Login</Button>} */}
              </div>
            </div>
          </div>
        )}
        <div className={styles.bodyContent} id="bodyContent" ref={containerRef}>
          {children}
          {pathname === "/" ||
            (pathname.includes("/collection/") && (
              <div className={styles.walletSidebar} id="sidebarContent">
                {/* <ins className="aso-zone" data-zone="115239"></ins> */}
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
