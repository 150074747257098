import React from "react";
import styles from "../analytics.module.scss";

interface Campaign {
  name: string;
  launchDate: string;
  views: number;
  costs: number;
  ctr: string;
}

interface TopCampaignsSectionProps {
  campaigns;
}

/**
 * Renders the top campaigns section.
 *
 * @returns The JSX element of a table representing the top campaigns data.
 */
const TopCampaignsSection: React.FC<TopCampaignsSectionProps> = ({
  campaigns,
}: TopCampaignsSectionProps) => {
  return (
    <div className={styles.topCampaignsContainer}>
      <h3 className={styles.subHeading}>Top Campaigns</h3>
      <div className={styles.tableContainer}>
     
      <table className={styles.table}>
  <thead>
    <tr>
      <th>Name</th>
      <th>Launch Date</th>
      <th>Views</th>
      <th>Costs</th>
      <th>CTR</th>
    </tr>
  </thead>
  <tbody>
    {campaigns.length > 0 && (
      campaigns.map((campaign) => (
        <tr key={campaign?.name}>
          <td>{campaign?.name}</td>
          <td>{campaign?.limits.start_at ? campaign?.limits.start_at : campaign?.created_at}</td>
          <td>{campaign?.counters.impressions}</td>
          <td>{campaign?.limits.budget.total}</td>
          <td>{campaign?.counters.conversions}</td>
        </tr>
      ))
    )}
  </tbody>
</table>
{campaigns.length === 0 &&  (
      <div className={styles.noDataContainer} style={{ textAlign: "center", marginTop: "50px" }}>
      
          <h1 className={styles.content}>
            No data found.
          </h1>
      </div>
    )}
      </div>
    </div>
  );
};

export default TopCampaignsSection;